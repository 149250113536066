<template>
  <div>
      <nav-bar :wallet='false'></nav-bar>
      <div class="row justify-content-center p-0 m-0">
        <div class="col-12 col-lg-8 p-0 m-0">
          <div  class="px-3">
<h2 class="doc-title">PRIVACY POLICY</h2>
<p class="doc-pera">
  This privacy policy applies between you, the User of this Website, and fuse.gold limited , the owner and provider of this
Website. fuse.gold limited takes the privacy of your information very seriously. This privacy policy applies to our use of
any and all Data collected by us or provided by you in relation to your use of the Website.
</p>
<p class="doc-pera">
  This privacy policy should be read alongside, and in addition to, our Terms and Conditions, which can be found at: fuse.gold
/terms&conditions.
</p>
<p class="doc-pera"> <b> Please read this privacy policy carefully. </b> </p>
<h3 class="doc-subtitle mb-4"> Definitions and interpretation </h3>
<p class="doc-pera">  <span class="pr-4"> 1. </span> In this privacy policy, the following definitions are used: </p>
<table>
  <tr v-for="d in table" :key="d.t">
    <td class="p-2 border border-dark"> <b>{{d.t}}</b> </td>
    <td class="p-2 border border-dark">{{d.p}} </td>
  </tr>
</table>
<p class="doc-pera">  <span class="pr-4"> 2. </span> In this privacy policy, unless the context requires a different interpretation: </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> In this privacy policy, unless the context requires a different interpretation: </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span>  the singular includes the plural and vice versa;
 references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or appendices of
this privacy policy; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> c. </span>  a reference to a person includes firms, companies, government entities, trusts and partnerships; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> d. </span> "including" is understood to mean "including without limitation"; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> e. </span> reference to any statutory provision includes any modification or amendment of it; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> f. </span> the headings and sub-headings do not form part of this privacy policy. </p>
<h3 class="doc-subtitle mb-4">Scope of this privacy policy</h3>
<p class="doc-pera">  <span class="pr-4"> 3. </span> This privacy policy applies only to the actions of fuse.gold limited and Users with respect to this Website. It does not
extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide
to social media websites. </p>
<p class="doc-pera">  <span class="pr-4"> 4. </span> For purposes of the applicable Data Protection Laws, fuse.gold limited is the "data controller". This means that fuse.
gold limited determines the purposes for which, and the manner in which, your Data is processed. </p>
<h3 class="doc-subtitle mb-4"> Data collected </h3>
<p class="doc-pera">  <span class="pr-4"> 5. </span>We may collect the following Data, which includes personal Data, from you: </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> name; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span>date of birth; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> c. </span>  contact Information such as email addresses and telephone numbers; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> d. </span> demographic information such as postcode, preferences and interests; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> e. </span>financial information such as credit / debit card numbers; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> f. </span> IP address (automatically collected); </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> g. </span>operating system (automatically collected); </p>
<p class="doc-pera">   in each case, in accordance with this privacy policy. </p>

<h3 class="doc-subtitle mb-4"> How we collect Data </h3>
<p class="doc-pera">  <span class="pr-4"> 6. </span> We collect Data in the following ways: </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> data is given to us by you; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span>data is received from other sources; and </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> c. </span>data is collected automatically. </p>

<h3 class="doc-subtitle mb-4"> Data that is given to us by you </h3>
<p class="doc-pera">  <span class="pr-4"> 7. </span>fuse.gold limited will collect your Data in a number of ways, for example: </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> when you contact us through the Website, by telephone, post, e-mail or through any other means; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span>when you register with us and set up an account to receive our products/services; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> c. </span>when you complete surveys that we use for research purposes (although you are not obliged to respond to them); </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> d. </span> when you enter a competition or promotion through a social media channel; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> e. </span>when you make payments to us, through this Website or otherwise; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> f. </span> when you elect to receive marketing communications </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> f. </span>when you use our services; </p>
<p class="doc-pera">   in each case, in accordance with this privacy policy. </p>


<h3 class="doc-subtitle mb-4"> Data that is received from third parties </h3>
<p class="doc-pera">  <span class="pr-4"> 8. </span>fuse.gold limited will receive Data about you from the following third parties: </p>

<h3 class="doc-subtitle mb-4"> Data that is received from publicly available third parties sources </h3>
<p class="doc-pera">  <span class="pr-4"> 9. </span>We will receive Data about you from the following publicly available third party sources: </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> Google Analytics . </p>

<h3 class="doc-subtitle mb-4"> Data that is collected automatically </h3>
<p class="doc-pera">  <span class="pr-4"> 10. </span>To the extent that you access the Website, we will collect your Data automatically, for example: </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span>we automatically collect some information about your visit to the Website. This information helps us to make
improvements to Website content and navigation, and includes your IP address, the date, times and frequency with
which you access the Website and the way you use and interact with its content. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span>we will collect your Data automatically via cookies, in line with the cookie settings on your browser. For more
information about cookies, and how we use them on the Website, see the section below, headed "Cookies". </p>

<h3 class="doc-subtitle mb-4"> Our use of Data </h3>
<p class="doc-pera">  <span class="pr-4"> 11. </span>Any or all of the above Data may be required by us from time to time in order to provide you with the best possible
service and experience when using our Website. Specifically, Data may be used by us for the following reasons: </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> internal record keeping; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span> improvement of our products / services; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> c. </span> transmission by email of marketing materials that may be of interest to you; </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> d. </span> contact for market research purposes which may be done using email, telephone, fax or mail. Such information
may be used to customise or update the Website; </p>
<p class="doc-pera">   in each case, in accordance with this privacy policy. </p>
<p class="doc-pera">  <span class="pr-4"> 12. </span>We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests. If you are
not satisfied with this, you have the right to object in certain circumstances (see the section headed "Your rights"
below).</p>
<p class="doc-pera">  <span class="pr-4"> 13. </span>For the delivery of direct marketing to you via e-mail, we'll need your consent, whether via an opt-in or soft-opt-in:</p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> soft opt-in consent is a specific type of consent which applies when you have previously engaged with us (for
example, you contact us to ask us for more details about a particular product/service, and we are marketing similar
products/services). Under "soft opt-in" consent, we will take your consent as given unless you opt-out. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span> for other types of e-marketing, we are required to obtain your explicit consent; that is, you need to take positive
and affirmative action when consenting by, for example, checking a tick box that we'll provide. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> c. </span> if you are not satisfied with our approach to marketing, you have the right to withdraw consent at any time. To
find out how to withdraw your consent, see the section headed "Your rights" below. </p>
<p class="doc-pera">  <span class="pr-4"> 14. </span>When you register with us and set up an account to receive our services, the legal basis for this processing is the
performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract.</p>

<p class="doc-pera">  <span class="pr-4"> 15. </span>We may use your Data to show you fuse.gold limited adverts and other content on other websites. If you do not want
us to use your data to show you fuse.gold limited adverts and other content on other websites, please turn off the
relevant cookies (please refer to the section headed "Cookies" below).</p>

<h3 class="doc-susbtitle"> Who we share Data with </h3>

<p class="doc-pera">  <span class="pr-4"> 16. </span>We may share your Data with the following groups of people for the following reasons:</p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> our employees, agents and/or professional advisors - to ensure the customer gets the best service ;</p>

<p class="doc-pera">in each case, in accordance with this privacy policy.</p>
<h3 class="doc-subtitle mb-4"> Keeping Data secure </h3>
<p class="doc-pera">  <span class="pr-4"> 17. </span>We will use technical and organisational measures to safeguard your Data, for example:</p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span> access to your account is controlled by a password and a user name that is unique to you. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> c. </span> we store your Data on secure servers. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> d. </span>
payment details are encrypted using SSL technology (typically you will see a lock icon or green address bar (or
both) in your browser when we use this technology. </p>

<p class="doc-pera">  <span class="pr-4"> 18. </span> Technical and organisational measures include measures to deal with any suspected data breach. If you suspect any
misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via this e-mail
address: info@fuse.gold.</p>
<p class="doc-pera">  <span class="pr-4"> 19. </span>If you want detailed information from Get Safe Online on how to protect your information and your computers and
devices against fraud, identity theft, viruses and many other online problems, please visit www.getsafeonline.org. Get
Safe Online is supported by HM Government and leading businesses.</p>
<h3 class="doc-subtitle mb-4"> Data retention </h3>
<p class="doc-pera">  <span class="pr-4"> 20. </span>Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for the
period necessary to fulfil the purposes outlined in this privacy policy or until you request that the Data be deleted.</p>
<p class="doc-pera">  <span class="pr-4"> 21. </span>Even if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory purposes.</p>
<h3 class="doc-subtitle mb-4"> Your rights  </h3>
<p class="doc-pera">  <span class="pr-4"> 22. </span>You have the following rights in relation to your Data:</p>
<p class="doc-pera">  <span class="pl-3 pr-1"> a. </span> <b>Right to access</b> 
- the right to request (i) copies of the information we hold about you at any time, or (ii) that we
modify, update or delete such information. If we provide you with access to the information we hold about you,
we will not charge you for this, unless your request is "manifestly unfounded or excessive." Where we are legally
permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons why. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> b. </span> <b>Right to correct</b>- the right to have your Data rectified if it is inaccurate or incomplete. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> c. </span> <b>Right to erase</b> - the right to request that we delete or remove your Data from our systems. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> d. </span> <b>Right to restrict our use of your Data</b> - the right to "block" us from using your Data or limit the way in which we can use it. </p>
<p class="doc-pera">  <span class="pl-3 pr-1"> e. </span> <b>Right to data portability</b> - the right to request that we move, copy or transfer your Data.</p>
<p class="doc-pera">  <span class="pl-3 pr-1"> f. </span> <b>Right to object</b> - the right to object to our use of your Data including where we use it for our legitimate interests. </p>
<p class="doc-pera">  <span class="pr-4"> 23. </span> To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of your Data
(where consent is our legal basis for processing your Data), please contact us via this e-mail address: info@fuse.gold.</p>
<p class="doc-pera">  <span class="pr-4"> 24. </span> If you are not satisfied with the way a complaint you make in relation to your Data is handled by us, you may be able
to refer your complaint to the relevant data protection authority. For the UK, this is the Information Commissioner's
Office (ICO). The ICO's contact details can be found on their website at https://ico.org.uk/.</p>
<p class="doc-pera">  <span class="pr-4"> 25. </span> It is important that the Data we hold about you is accurate and current. Please keep us informed if your Data changes
during the period for which we hold it. </p>
<h3 class="doc-subtitile"> Links to other websites </h3>
<p class="doc-pera">  <span class="pr-4"> 26. </span>
 This Website may, from time to time, provide links to other websites. We have no control over such websites and are
not responsible for the content of these websites. This privacy policy does not extend to your use of such websites. You
are advised to read the privacy policy or statement of other websites prior to using them. </p>

<h3 class="doc-subtitile"> Changes of business ownership and control </h3>
<p class="doc-pera">  <span class="pr-4"> 27. </span>
fuse.gold limited may, from time to time, expand or reduce our business and this may involve the sale and/or the
transfer of control of all or part of fuse.gold limited . Data provided by Users will, where it is relevant to any part of our
business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the
terms of this privacy policy, be permitted to use the Data for the purposes for which it was originally supplied to us.
</p>
<p class="doc-pera">  <span class="pr-4"> 28. </span> We may also disclose Data to a prospective purchaser of our business or any part of it. </p>
<p class="doc-pera">  <span class="pr-4"> 29. </span>In the above instances, we will take steps with the aim of ensuring your privacy is protected. </p>
<h3 class="doc-subtitile"> Cookies </h3>
<p class="doc-pera">  <span class="pr-4"> 30. </span>
This Website may place and access certain Cookies on your computer. fuse.gold limited uses Cookies to improve
your experience of using the Website and to improve our range of products. fuse.gold limited has carefully chosen
these Cookies and has taken steps to ensure that your privacy is protected and respected at all times. </p>
<p class="doc-pera">  <span class="pr-4"> 31. </span> All Cookies used by this Website are used in accordance with current UK and EU Cookie Law. </p>
<p class="doc-pera">  <span class="pr-4"> 32. </span>
Before the Website places Cookies on your computer, you will be presented with a message bar requesting your
consent to set those Cookies. By giving your consent to the placing of Cookies, you are enabling fuse.gold limited to
provide a better experience and service to you. You may, if you wish, deny consent to the placing of Cookies; however
certain features of the Website may not function fully or as intended.
</p>
<p class="doc-pera">  <span class="pr-4"> 33. </span> This Website may place the following Cookies: </p>
<!-- <p class="doc-pera">  <span class="pr-4"> 26. </span> </p> -->
<table>
  <tr> <th class="p-2 border border-dark"> <b> Type of Cookie </b> </th> <th class="p-2 border border-dark"> <b> Purpose </b> </th> </tr>
  <tr v-for="d in tabl" :key="d.t">
    <td class="p-2 border border-dark"> <b>{{d.t}}</b> </td>
    <td class="p-2 border border-dark">{{d.p}} </td>
  </tr>
  </table>
<p class="doc-pera">  <span class="pr-4"> 34. </span> You can find a list of Cookies that we use in the Cookies Schedule. </p>
<p class="doc-pera">  <span class="pr-4"> 35. </span> You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept </p>
<p class="doc-pera">  <span class="pr-4"> 36. </span> You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept
Cookies but this can be changed. For further details, please see the help menu in your internet browser. You can switch
off Cookies at any time, however, you may lose any information that enables you to access the Website more quickly
and efficiently. </p>
<p class="doc-pera">  <span class="pr-4"> 37. </span> You can choose to delete Cookies at any time; however, you may lose any information that enables you to access the
Website more quickly and efficiently including, but not limited to, personalisation settings. </p>
<p class="doc-pera">  <span class="pr-4"> 38. </span>  It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and guidance
provided by the developer of your internet browser if you are unsure about adjusting your privacy settings. </p>
<p class="doc-pera">  <span class="pr-4"> 39. </span> For more information generally on cookies, including how to disable them, please refer to aboutcookies.org. You will
also find details on how to delete cookies from your computer. </p>
<h3 class="doc-subtitle mb-4"> General </h3>
<p class="doc-pera">  <span class="pr-4"> 40. </span> You may not transfer any of your rights under this privacy policy to any other person. We may transfer our rights
under this privacy policy where we reasonably believe your rights will not be affected. </p>
<p class="doc-pera">  <span class="pr-4"> 41. </span> If any court or competent authority finds that any provision of this privacy policy (or part of any provision) is invalid,
illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the
validity and enforceability of the other provisions of this privacy policy will not be affected. </p>
<p class="doc-pera">  <span class="pr-4"> 42. </span> Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a
waiver of that, or any other, right or remedy. </p>
<p class="doc-pera">  <span class="pr-4"> 43. </span> This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising
under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts. </p>
<h3 class="doc-subtitle mb-4"> Changes to this privacy policy </h3>
<p class="doc-pera">  <span class="pr-4"> 44. </span> fuse.gold limited reserves the right to change this privacy policy as we may deem necessary from time to time or as
may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted
the terms of the privacy policy on your first use of the Website following the alterations.
You may contact fuse.gold limited by email at info@fuse.gold. </p>
<h3 class="doc-subtitle mb-4"> Attribution </h3>
<p class="doc-pera">  <span class="pr-4"> 45. </span> This privacy policy was created using a document from Rocket Lawyer (https://www.rocketlawyer.com/gb/en).
This privacy policy was created on 11 August 2022. </p>
<h3 class="doc-subtitle mb-4"> Cookies </h3>
<p class="doc-pera"> 
Below is a list of the cookies that we use. We have tried to ensure this is complete and up to date, but if you think that we
have missed a cookie or there is any discrepancy, please let us know. </p>
<p class="doc-pera"> <b> Strictly necessary </b> </p>
<p class="doc-pera"> We use the following strictly necessary cookies:  </p>

<table>
  <tr> <th class="p-2 border border-dark"> <b> Description </b> </th> <th class="p-2 border border-dark"> <b> Purpose </b> </th> </tr>
  <tr >
    <td class="p-2 border border-dark">Retargeting  </td>
    <td class="p-2 border border-dark"> We use this session cookie to remember you and maintain your session

whilst you are using our website  </td>
  </tr>
  </table>

<p class="doc-pera"> <b> Functionality </b> </p>
<p class="doc-pera"> We use the following functionality cookies:  </p>


<table>
  <tr> <th class="p-2 border border-dark"> <b> Description </b> </th> <th class="p-2 border border-dark"> <b> Purpose </b> </th> </tr>
  <tr >
    <td class="p-2 border border-dark"> Traffic </td>
    <td class="p-2 border border-dark"> We use this cookie to identify your computer and analyse traffic patterns on our

website </td>
  </tr>
  </table>

<p class="doc-pera"> <b> Analytical/performance </b> </p>
<p class="doc-pera"> We use the following analytical/performance cookies:  </p>


<table>
  <tr> <th class="p-2 border border-dark"> <b> Description </b> </th> <th class="p-2 border border-dark"> <b> Purpose </b> </th> </tr>
  <tr >
    <td class="p-2 border border-dark"> Analytics </td>
    <td class="p-2 border border-dark"> We use this cookie to help us analyse how users use the website </td>
  </tr>
  </table>


<p class="doc-pera"> <b> Targeting </b> </p>
<p class="doc-pera"> We use the following targeting cookies: </p>


<table>
  <tr> <th class="p-2 border border-dark"> <b> Description </b> </th> <th class="p-2 border border-dark"> <b> Purpose </b> </th> </tr>
  <tr >
    <td class="p-2 border border-dark"> Targeting </td>
    <td class="p-2 border border-dark"> We use this cookie to enable us to show you adverts while you are browsing our

website and other websites on the internet </td>
  </tr>
  </table>



      </div>
        </div>
      </div>
    <footer-section></footer-section>

  </div>
</template>

<script>


export default {
   components:{
   },
    name: "PrivacyPolicy.vue",
    components:{
    NavBar:() => import('@/components/NavBar.vue'),FooterSection:() => import('@/components/FooterSection'),

    },
    data(){
      return {
        tabl:[
          {t:`Strictly necessary cookies`,p:` These are cookies that are required for the operation of
our website. They include, for example, cookies that
enable you to log into secure areas of our website, use a
shopping cart or make use of e-billing services.`},
          {t:`Analytical/performance cookies`,p:`
 They allow us to recognise and count the number of
visitors and to see how visitors move around our website
when they are using it. This helps us to improve the way
our website works, for example, by ensuring that users
are finding what they are looking for easily.`},
          {t:`Functionality cookies`,p:`
 These are used to recognise you when you return to our
website. This enables us to personalise our content for
you, greet you by name and remember your preferences
(for example, your choice of language or region). By
using the Website, you agree to our placement of
functionality cookie.
`},
          {t:`Targeting cookies`,p:``},
          {t:``,p:`
 These cookies record your visit to our website, the pages
you have visited and the links you have followed. We
will use this information to make our website and the
advertising displayed on it more relevant to your
interests. We may also share this information with third`},
        ],        
        table:[
      {t:'Data',p:`collectively all information that you submit to fuse.gold limited via the Website. This definition

incorporates, where applicable, the definitions provided in the Data Protection Laws;`},
{t:`Cookies`,p:`
 a small text file placed on your computer by this Website when you visit certain parts of the Website and
/or when you use certain features of the Website. Details of the cookies used by this Website are set out
in the clause below (Cookies);`},
{t:`Data
Protection
Laws`,p:`any applicable law relating to the processing of personal Data, including but not limited to the GDPR,
and any national implementing and supplementary laws, regulations and secondary legislation;
`},
{t:`GDPR`,p:`the UK General Data Protection Regulation;`},
{t:`fuse.gold
limited ,
we or us`,p:`
fuse.gold limited , a company incorporated in England and Wales with registered number 13267453
whose registered office is at 2nd Floor Romy House,, 163-167 Kings Road Brentwood, Essex,
CM14 4EG;`},
{t:`UK and
EU
Cookie
Law`,p:`the Privacy and Electronic Communications (EC Directive) Regulations 2003 as amended by the
Privacy and Electronic Communications (EC Directive) (Amendment) Regulations 2011 & the Privacy
and Electronic Communications (EC Directive) (Amendment) Regulations 2018;
`},
{t:`User or
you`,p:`
any third party that accesses the Website and is not either (i) employed by fuse.gold limited and acting
in the course of their employment or (ii) engaged as a consultant or otherwise providing services to fuse.
gold limited and accessing the Website in connection with the provision of such services; and`},
{t:`Website`,p:`the website that you are currently using, fuse.gold, and any sub-domains of this site unless expressly`}
 
        ]
      }
    }
}
</script>

<style>

</style>